import api from '../../services/Api'
import { Desenvolvedor, IDesenvolvedorDetail } from "../../Types/Desenvolvedor";
import DesenvolvedorGateway from "./desenvolvedorGateway";
import { BASE_URL } from "../../env/variables";
import { CaptacaoDetail, ISolicitacaoCaptacao } from "../../Types/Captacao";
import { DesenvolvedorDashboard, Pendencia } from "../../Types/DesenvolvedorDashboard";
import { IProgressoObra, IProjeto, Unidade, Projecao, IItensRecebiveis, IRating } from "../../Types/Projeto";
import { SPE } from "../../Types/General";
import { BoletoPagamento, Extrato, HistoricoPagamentos } from "../../Types/Extrato";
import { Socio, SocioDetailBase, SocioTipo } from "../../Types/Socio";
import { IDadoPeriodico } from "../../Types/DadosPeriodicos";
import { Incorporadora } from "../../Types/Incorporadora";
import { acessoIncorporadoraService } from '@/services/AcessoIncorporadora';
import { Comunicado } from "../../Types/Comunicado"
import { Operador } from "../../Types/Operador";
import { PluginsAtivos } from '@/Types/Plugin';
import { IContaSienge } from '@/Types/Sienge';
import { Mensagem } from '@/Types/Mensagem';
import { Chats, MensagemChat } from '@/Types/MensagemChat';

const SERVICE_URL = BASE_URL + 'api/incorporador/'
const SAAS_URL = BASE_URL + 'saas/'
const CORE_URL = BASE_URL + 'administracao/'

export default class DesenvolvedorGatewayHttp implements DesenvolvedorGateway {

    // GET METHODS
    async getProgressoObra(projeto_id: number): Promise<void | IProgressoObra[]> {
        const response = await api.get<IProgressoObra[]>(`${SERVICE_URL}prestacao/${projeto_id}`);
        return response;
    }

    async getUnidades(projeto_id: number): Promise<void | Unidade[]> {
        const response = await api.get<Unidade[]>(`${SERVICE_URL}unidades/${projeto_id}`);
        return response;
    }

    async getProjecoes(projeto_id: number): Promise<void | Projecao[]> {
        const response = await api.get<Projecao[]>(`${SERVICE_URL}projecoes/${projeto_id}`);
        return response;
    }

    async getRating(ano: number, periodo: string): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}gerar_rating/${ano}/${periodo}`, 'blob');
        return response;
    }

    async getRatingProjeto(projeto_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}gerar_rating/projeto/${projeto_id}`, 'blob');
        return response;
    }

    async getTabelaUnidades(projeto: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}gerar-planilha-unidades/${projeto}`, 'blob');
        return response;
    }

    async getTabelaProjecoes(projeto: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}gerar-planilha-projecoes/${projeto}`, 'blob');
        return response;
    }

    async getTabelaUnidadesPadrao(): Promise<any> {
        const response = await api.get<any>(`${CORE_URL}projetos/planilha-unidades-modelo`, 'blob');
        return response;
    }
    
    async getTabelaProjecaoPadrao(): Promise<any> {
        const response = await api.get<any>(`${CORE_URL}projetos/planilha-projecao-modelo`, 'blob');
        return response;
    }
    
    async getTabelaFluxoPagamentos(captacao_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}captacao/${captacao_id}/export_fluxo_pagamentos/`, 'blob');
        return response;
    }

    async getTabelaFluxoDeCaixa(projeto_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}export_fluxo_caixa/projeto/${projeto_id}`, 'blob');
        return response;
    }
    async getFluxoDeRecebiveis(projeto_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}export_fluxo_de_recebiveis/projeto/${projeto_id}`, 'blob');
        return response;
    }

    async getPendenciasViabilidade(projeto_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}viabilidade/${projeto_id}/pendencias/`);
        return response
    }

    async getOperadores(): Promise<void | Operador[]> {
        const response = await api.get<Operador[]>(`${SERVICE_URL}operadores`)
        return response;
    }

    async gerarBoleto(pagamento: BoletoPagamento): Promise<any> {
        const response = await api.post<any>(pagamento, `${CORE_URL}api/fitbank/gerar-boleto/`, 'application/json');
        return response;
    }

    cancelarBoleto(): Promise<{ message: string; }> {
        throw new Error("Method not implemented.");
    }

    async getPendencias(): Promise<void | Pendencia> {
        const response = await api.get<Pendencia>(`${SERVICE_URL}pendencias`);
        return response;
    }

    async getIncorporadoraAtiva(): Promise<Incorporadora | void> {
        const response = await api.get<Incorporadora>(`${SERVICE_URL}incorporadora`)
        return response;
    }
    async setIncorporadora(id: number): Promise<any> {
        const response = await api.post<any>({id}, `${SERVICE_URL}incorporadoras`, 'application/json');
        return response;
    }
    async getIncorporadoras(): Promise<Incorporadora[] | void> {
        const response = await api.get<Incorporadora[]>(`${SERVICE_URL}incorporadoras`)
        return response;
    }

    async getDadosPeriodicos(): Promise<void | {dados_periodicos_serializer: IDadoPeriodico[]}> {
        const response = await api.get<{dados_periodicos_serializer: IDadoPeriodico[]}>(`${SERVICE_URL}dados-periodicos`)
        return response;
    }
    
    async getExtratos(limit: number | string = ''): Promise<Extrato[] | void> {
        const response = await api.get<Extrato[]>(`${SERVICE_URL}extratos/${limit}`)
        return response;
    }

    async getDeclaracaoImpostoRetido(data_inicial_str: string, data_final_str: string): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}download-declaracao-imposto-retido/${data_inicial_str}/${data_final_str}`, 'blob');
        return response; 
    }
    
    async getHistorico(): Promise<HistoricoPagamentos[] | void> {
        const response = await api.get<HistoricoPagamentos[]>(`${SERVICE_URL}extratos/historico`)
        return response;
    }

    async getChartData(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}charts-data/`)
        return response;
    }

    async getDesenvolvedor(): Promise<Desenvolvedor | void> {
        const response = await api.get<Desenvolvedor>(SERVICE_URL);
        return response;
    }

    async getDesenvolvedorDetail(): Promise<IDesenvolvedorDetail | void> {
        const response = await api.get<IDesenvolvedorDetail>(`${SERVICE_URL}detail/`);
        return response;
    }

    async getDashboard(): Promise<DesenvolvedorDashboard | void> {
        const response = await api.get<DesenvolvedorDashboard>(`${SERVICE_URL}dashboard`);
        return response;
    }
    async getProjetoDetail(id: number): Promise<IProjeto | void> {
        const url = SERVICE_URL + 'projeto/' + id;
        const response = await api.get<IProjeto>(url);
        return response;
    }
    async getRatingDetail(id: number): Promise<IRating | void> {
            const url = SERVICE_URL + 'rating_detail/' + id;
            const response = await api.get<IRating>(url);
            return response;
        }
    async getCaptacaoDetail(id: number): Promise<CaptacaoDetail | void> {
        const url = SERVICE_URL + 'captacao/' + id;
        const response = await api.get<CaptacaoDetail>(url);
        return response
    }
    async getSolicitacaoCaptacaoDetail(id: number): Promise<ISolicitacaoCaptacao | void> {
        const url = SERVICE_URL + 'solicitar-captacao/' + id;
        const response = await api.get<ISolicitacaoCaptacao>(url);
        return response

    }
    async getMensagemSolicitacaoDetail(id: number): Promise<Mensagem[] | void> {
        const response = await api.get<Mensagem[]>(`${SERVICE_URL}lista-mensagem-solicitacao/${id}`)
        return response;

    }
    async getSpe(id: number): Promise<Socio | void> {
        const response = await api.get<Socio>(`${BASE_URL}api/projetos-pj/${id}`);
        return response;
    }
    async getSpes(): Promise<SPE[] | void> {
        const response = await api.get<SPE[]>(`${BASE_URL}api/projetos-pj`);
        return response;
    }

    async getSocios(): Promise<Socio[] | void> {
        const response = await api.get<Socio[]>(`${SERVICE_URL}socios`);
        return response;
    }

    async getSocioDetail<Type extends SocioDetailBase>(id: number, tipo: SocioTipo): Promise<Type | void> {
        const response = await api.get<Type>(`${SERVICE_URL}socio-detail/${tipo}/${id}`)
        return response;
    }

    async getComunicados(id: number): Promise<Comunicado[] | void> {
        const response = await api.get<Comunicado[]>(`${SERVICE_URL}lista-comunicados/${id}`)
        return response;
    }
    
    async getArquivoHistoricoPagamentos(): Promise<any>{
        const response = await api.get<any>(`${SERVICE_URL}download-historico-pagamentos/`,'blob')
        return response;
    }

    async getArquivoInvestidoresCaptacao(captacao_id: number): Promise<any>{
        const response = await api.get<any>(`${SERVICE_URL}captacao-exportar-investidores/${captacao_id}`,'blob')
        return response;
    }

    async getPluginsAtivos(): Promise<PluginsAtivos | void> {
        const response = await api.get<PluginsAtivos | void>(`${SERVICE_URL}plugins-ativos/`);
        return response;
    }

    async getProjetosSienge(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}listar-projetos-sienge`)
        return response;
    }

    async getProjetosSincronizados(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}lista-projetos-sincronizados`);
        return response;
    }

    async getContaSienge(): Promise<IContaSienge | void> {
        const response = await api.get<IContaSienge>(`${SERVICE_URL}conta-sienge-detalhes`)
        return response;
    }

    async getPlanilhaRecebiveis(projeto_id: number): Promise<any> {
        const response = await api.get<any>(`${SAAS_URL}/projetos/${projeto_id}/recebiveis/export`)
        return response;
    }

    async getTestIntegration(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}teste-integracao/`)    
        return response;
    }

    async getChats(): Promise<Chats> {
        const response = await api.get<any>(`${SERVICE_URL}list-chat`)
        return response;
    }

    async getMensagensChat(id: number): Promise<MensagemChat> {
        const response = await api.get<any>(`${SERVICE_URL}list-mensagem-chat/${id}`)
        return response;
    }

    async getAssuntoETopicoChat():Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}assuntos-topicos/`)
        return response;
    }

    async getFAQChat():Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}faq-chat/`)
        return response;
    }

    //////////////
    //POST METHODS
    //////////////
    async postNovoChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}novo-chat/`)   
        return response;
    }

    async removerChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-chat/`);
        return response;
    }

    async postMensagemChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}nova-mensagem-chat/`)   
        return response;
    }

    async postImportarProjetoSienge(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}importar-projeto-sienge`);
        return response;
    }

    async postNovaContaSienge(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}create/conta-sienge/`);
        return response;
    }

    async atualizarImagemProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-imagem-projeto/`);
        return response;
    }
    
    async postSolicitarCaptacao(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}solicitar-captacao/`);
        return response;
    }

    async postMensagemSolicitarCaptacao(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}nova-mensagem-solicitacao/`);
        return response;
    }

    async postNovoProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novo-projeto/`);
        return response;
    }

    async removerImagensProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-imagens-projeto/`)
        return response;
    }
    
    async removerDesenhosProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-desenhos-projeto/`)
        return response;
    }

    async adicionarImagensProjeto(body:  FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novas-imagens-projeto/`)
        return response;
    }

    async adicionarDesenhosProjeto(body:  FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novos-desenhos-projeto/`)
        return response;
    }

    async postNovoSocioPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}projetos-pj/novo-socio-pj/`);
        return response;
    }

    async postNovoSocioPF(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}projetos-pj/novo-socio-pf/`);
        return response;
    }

    async postNovoDadoPeriodico(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novos-dados-periodicos/`);
        return response;
    }
    async postRatingDetail(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}rating_detail`);
        return response;
    }

    async postUpdateDadoPeriodico(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-dados-periodicos/`);
        return response;
    }

    async postUpdateDesenvolvedor(body: FormData): Promise<any>{
        const response = await api.post<any>(body, `${SERVICE_URL}configuracoes/update/`);
        return response;
    }

    async postNovaPrestacao(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}prestacao-contas/`);
        return response;
    }

    async postTabelaUnidades(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-tabela-unidades/`);
        return response;
    }

    async postTabelaProjecoes(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-tabela-projecoes/`);
        return response;
    }

    async postNovoOperador(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}criar-operador/`);
        return response;
    }

    async postNovoProjetoPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novo-projeto-pj/`);
        return response;
    }

    async postUpdateProjetoPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-projeto-pj/`);
        return response;
    }
    
    async postVerificaSenhaProjeto(body: { projeto_slug: string, token: string }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}verificar-senha-projeto/`);
        return response;
    }

    async postImportSocio(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}import-socio/`);
        return response;
    }

    async postRemoveSocio(body: { base_socio: number; projeto_pj: boolean; socio: string; }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remove-socio/`);
        return response;
    }

    async removerPrestacaoContas(body: { id: number; }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-prestacao-contas/`);
        return response;
    }

    async removerOperador(body: { operador_id: number; }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-operador/`);
        return response;
    }

    async updateOperador(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-operador/`);
        return response;
    }

    async updateProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-projeto/`);
        return response;
    }

    async updateSolicitacaoCaptacao(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-solicitacao-captacao/`);
        return response;
    }
    async removerSolicitacaoCaptacao(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}deletar-solicitacao/`);
        return response;
    }

    async removerProjeto(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}deletar-projeto/`);
        return response;
    }

    async updateSocioPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}projetos-pj/update-socio-pj/`);
        return response;
    }

    async updateSocioPF(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}projetos-pj/update-socio-pf/`);
        return response;
    }

    async postCadastroIncorporadora(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}novo-incorporador/`);
        return response;
    }

    async atualizarProjetoSienge(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-projeto-sienge/`);
        return response;    
    }

    async atualizarProjetoSiengeAtualizacaoAutomatica(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}projetos-sincronizado-atualizacao-automatica/`);
        return response;
    }

}